<template>
  <div class="d-flex flex-column justify-between align-center heading__row">
    <setting-dev-svg />
    <h5>
      Раздел находится в разработке
    </h5>
    <p>
      Постараемся доделать всё как можно скорее 😉
    </p>
  </div>
</template>

<script>
import '@/styles/dashboards.styl'
import SettingDevSvg from '@/components/Dashboard/SettingDevSvg.vue'

export default {
  components: {
    SettingDevSvg
  },

  data () {
    return {}
  }
}
</script>

<style lang="stylus" scoped>
  .heading__row {
    padding 64px 0 100px 0!important

    h5 {
      margin-top 20px
      font-size 18px
      color #3C3C47
    }

    p {
      font-size 16px
      color #7B7B85
    }
  }
</style>
