<template>
  <tile v-if="showGrafic">
    <template #heading>
      <div class="d-flex justify-between">
        <div class="d-flex align-center">
          {{ GraficsName[heading] }}
        </div>
      </div>
    </template>
    <div
      v-if="loadind"
      class="dashboard__loader"
    >
      <loader />
    </div>
    <chart
      v-else
      class="chart-multiple-areas"
      :options="chartOptions"
    />
  </tile>
</template>

<script>
import { Chart } from 'highcharts-vue'
import Tile from '@/components/Common/Tile.vue'
import Loader from '@/components/Common/Loader.vue'
import Measures from '@/constants/measure-name'
import GraficsName from '@/constants/dashboards_grafics_name'
import formatter from '@/utils/formatterTooltip'
import formatterLegend from '@/utils/formatterLegend'
import creater from '@/utils/creater-data'
import '@/styles/grafics.styl'

export default {

  components: {
    Chart,
    Tile,
    Loader
  },

  props: {
    heading: {
      type: String,
      default: ''
    },
    measure: {
      type: String,
      default: ''
    },
    dates: {
      type: Array,
      required: true
    },
    points: {
      type: Array,
      required: true
    },
    checkedMetricsIds: {
      type: Array,
      default: () => []
    },
    freeColorIndexs: {
      type: Array,
      default: () => []
    },
    showDay: {
      type: Boolean,
      default: false
    },
    showGrafic: {
      type: Boolean,
      default: false
    },
    showWeek: {
      type: Boolean,
      default: false
    },
    loadind: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      Measures,
      GraficsName,
      chartOptions: {
        credits: {
          enabled: false
        },
        chart: {
          type: 'area',
          height: '0',
          style: {
            fontFamily: 'proxima_nova',
            fontWeight: 'normal'
          },
          backgroundColor: 'transparent'
        },
        title: {
          text: null
        },
        xAxis: {
          categories: this.dates,
          labels: {
            style: {
              color: '#7b7b85'
            }
          }
        },
        yAxis: [],
        plotOptions: {
          area: {
            marker: {
              symbol: 'circle',
              lineWidth: 1,
              lineColor: '#666666'
            }
          },
          series: {
            events: {
              legendItemClick: (e) => {
                this.changeChart(e)
              }
            },
            fillOpacity: 0.1,
            marker: {
              enabledThreshold: 100
            }
          }
        },
        tooltip: {
          enabled: true,
          crosshairs: true,
          shared: true,
          shadow: false,
          borderWidth: 0,
          backgroundColor: 'transparent',
          useHTML: true,
          headerFormat: formatter.formatterHeader(this.showDay, this.showWeek),
          pointFormatter: formatter.formatterSuperMultipleTooltip,
          footerFormat: '</table>'
        },
        colors: ['#1EABF1', '#F2453D', '#8DC252', '#F7A738', '#8554E1', '#D2927E', '#E725C8'],
        legend: {
          enabled: true,
          alignColumns: false,
          verticalAlign: 'top',
          symbolWidth: 0,
          symbolPadding: 10,
          labelFormatter: formatterLegend.simpleFormatLegend
        },
        series: this.points.map((point, index) => {
          const showBy = {
            show_day: this.showDay,
            show_week: this.showWeek
          }
          return creater.createMetricStatisticSeries(showBy, point, this.measure, this.heading, this.checkedMetricsIds, this.freeColorIndexs, index)
        })
      }
    }
  },

  watch: {
    showDay (value) {
      const showBy = {
        show_day: value,
        show_week: this.showWeek
      }
      this.chartOptions.series = this.points.map((point, index) => creater.createMetricStatisticSeries(showBy, point, this.measure, this.heading, this.checkedMetricsIds, this.freeColorIndexs, index))
      this.chartOptions.tooltip.headerFormat = formatter.formatterHeader(this.showDay, this.showWeek)
    },
    showWeek (value) {
      const showBy = {
        show_day: this.showDay,
        show_week: value
      }
      this.chartOptions.series = this.points.map((point, index) => creater.createMetricStatisticSeries(showBy, point, this.measure, this.heading, this.checkedMetricsIds, this.freeColorIndexs, index))
      this.chartOptions.tooltip.headerFormat = formatter.formatterHeader(this.showDay, this.showWeek)
    },
    checkedMetricsIds () {
      const showBy = {
        show_day: this.showDay,
        show_week: this.showWeek
      }
      this.chartOptions.series = this.points.map((point, index) => creater.createMetricStatisticSeries(showBy, point, this.measure, this.heading, this.checkedMetricsIds, this.freeColorIndexs, index))
    },
    points: {
      handler (value) {
        this.chartOptions.chart.height = value ? 460 : 90
        this.chartOptions.xAxis.categories = this.dates ? this.dates : []
        this.chartOptions.series = value ? value.map((point, index) => creater.createMetricStatisticSeries({
          show_day: this.showDay,
          show_week: this.showWeek
        }, point, this.measure, this.heading, this.checkedMetricsIds, this.freeColorIndexs, index)) : []
        this.chartOptions.yAxis = value ? value.map((point, index) => {
          const percentPoint = point.name === 'drr' ||
            point.name === 'ctr' ||
            point.name === 'wb_commission' ||
            point.name === 'wb_merchant_rating' ||
            point.name === 'cancelled_orders_by_seller_rating' ||
            point.name === 'cancelled_orders_rating' ||
            point.name === 'shipment_delay_rating' ||
            point.name === 'advert_orders_share'
          return {
            title: {
              text: null
            },
            min: 0,
            labels: {
              format: percentPoint ? '{value}%' : ''
            },
            opposite: !!(index % 2)
          }
        }) : []
      },
      deep: true
    }
  },

  methods: {
    changeChart (e) {
      const chart = e.target.chart
      const index = e.target.index
      if (chart.series[index].visible) {
        this.$emit('metric-checked', { name: chart.series[index].userOptions.name, colorIndex: chart.series[index].colorIndex })
      } else {
        this.$emit('metric-checked', { name: chart.series[index].userOptions.name, colorIndex: this.freeColorIndexs[0] })
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
  .chart-multiple-areas
    width 100%
    margin-top -30px
</style>
