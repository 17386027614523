<template>
  <modal
    :name="modalName"
    :max-width="680"
    :min-width="280"
    width="90%"
    height="auto"
    :adaptive="true"
    @before-open="onBeforeOpen"
    @closed="onClosed"
  >
    <div class="modal-container setting-metrics">
      <p class="setting-metrics__text">
        Выберите метрики
      </p>
      <p class="setting-metrics__subtitle">
        Настройте список и порядок отображаемых метрик для графика
      </p>
      <template>
        <div class="setting-metrics__content">
          <div class="setting-metrics__content__list">
            <div class="setting-metrics__content__list-header">
              <search
                :value="search"
                placeholder="Найти метрику"
                @input="term => $emit('update:search', term || null)"
              />
            </div>
            <div class="setting-metrics__content__selected-items scrollbar_primary">
              <div
                v-for="metric in metrics"
                :key="metric.name"
                class="setting-metrics__content__list-item"
              >
                <x-icon
                  name="chevron-right"
                  size="xxs"
                  @click="openingMetrics(metric.name)"
                />
                {{ metric.label }}
                <div
                  v-if="openMetrics.includes(metric.name)"
                >
                  <div
                    v-for="child in metric.children"
                    :key="child.name"
                    class="setting-metrics__content__list-child-item"
                  >
                    <checkbox
                      v-model="selectedItem"
                      :value="child.name"
                    >
                      {{ child.label }}
                    </checkbox>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="setting-metrics__content__selected">
            <div class="setting-metrics__content__selected-header">
              <x-btn
                class="ml-auto"
                color="white"
                prepend="return"
                @click="$modal.hide(modalName)"
              >
                Выбор по умолчанию
              </x-btn>
            </div>
            <div class="setting-metrics__content__selected-items scrollbar_primary">
              <div
                v-for="(selectItem, index) in selectedItem"
                :key="index"
              >
                <div class="setting-metrics__content__selected-item">
                  <span>{{ MetricName[selectItem] }}</span>
                  <button @click="deleteSelectedItem(selectItem)">
                    <svg-icon
                      class="svg-icon ml-1"
                      name="close"
                    />
                  </button>
                </div>
                <br>
              </div>
            </div>
          </div>
        </div>
      </template>
      <footer class="setting-metrics__footer">
        <x-btn
          color="gray"
          @click="$modal.hide(modalName)"
        >
          Отменить
        </x-btn>
        <x-btn
          @click="selectMetrics"
        >
          Выбрать
        </x-btn>
      </footer>
      <close-button
        @click="$modal.hide(modalName)"
      />
    </div>
  </modal>
</template>

<script>
import CloseButton from '@/components/Interface/CloseButton.vue'
import Search from '@/components/Product/Filter/Search.vue'
import Checkbox from '@/components/Interface/Checkbox.vue'
import MetricName from '@/constants/dashboard_mertics'

export default {
  components: {
    CloseButton,
    Search,
    Checkbox
  },

  data () {
    return {
      MetricName,
      modalName: 'setting-metrics-modal',
      isLoading: false,
      metrics: [],
      metricList: [],
      openMetrics: [],
      selectedItem: []
    }
  },

  methods: {
    async onBeforeOpen (e) {
      if (e.params) {
        const { metrics, selectedMetrics } = e.params
        this.selectedItem = selectedMetrics
        this.metricList = metrics
        metrics.forEach(metric => {
          this.openMetrics.push(metric.group_name)
          const index = this.metrics.findIndex(_ => _.name === metric.group_name)
          if (index === -1) {
            this.metrics.push({
              name: metric.group_name,
              label: MetricName[metric.group_name],
              children: [{
                name: metric.name,
                label: MetricName[metric.name]
              }]
            })
          } else {
            const newMetric = { ...this.metrics[index] }
            newMetric.children.push({
              name: metric.name,
              label: MetricName[metric.name]
            })
            this.metrics.splice(index, 1, newMetric)
          }
        })
      }
    },

    onClosed () {
      this.metrics = []
      this.openMetrics = []
      this.selectedItem = []
      this.metricList = []
    },

    selectMetrics () {
      const objSelectedItem = this.metricList.filter(item => this.selectedItem.includes(item.name))
      this.$emit('save-checked-metrics', objSelectedItem)
      this.$modal.hide(this.modalName)
    },

    deleteSelectedItem (item) {
      this.selectedItem = this.selectedItem.filter(metric => metric !== item)
    },

    openingMetrics (name) {
      if (this.openMetrics.includes(name)) {
        this.openMetrics = this.openMetrics.filter(metric => metric !== name)
      } else {
        this.openMetrics.push(name)
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
  .setting-metrics
    padding 30px

    &__text
      margin 12px 0
      font-size: $font-sizes.headline-5
      font-weight: $font-weights.bold
      color: $colors.gray-darkest-2

    &__subtitle
      font-size: 14px
      line-height 24px
      margin-bottom 12px

    &__content
      border-bottom 1px solid #CACAD3
      display flex

      &__list
        width 50%
        padding 10px

      &__selected
        width 50%
        border-left 1px solid #CACAD3
        padding 10px

      &__selected-header
        border-bottom 1px solid #CACAD3

      &__selected-items
        height 512px
        overflow-x hidden
        overflow-y scroll

      &__list-item
        margin 15px 0

      &__list-child-item
        display flex
        align-items center
        margin 10px 0 10px 15px

      &__selected-item
        display inline-block
        border 1px solid #CACAD3
        border-radius 4px
        vertical-align middle
        padding 10px
        margin 5px 0

        .svg-icon
          width 10px
          height 10px

    &__footer
      text-align right
      margin-top 20px
</style>
