<template>
  <svg
    width="72"
    height="72"
    viewBox="0 0 72 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="72"
      height="72"
      rx="36"
      fill="#27CEAA"
      fill-opacity="0.1"
    />
    <path
      d="M44.7023 29.2463C44.3938 29.5611 44.221 29.9843 44.221 30.425C44.221 30.8657 44.3938 31.2889 44.7023 31.6036L47.3964 34.2977C47.7111 34.6062 48.1343 34.779 48.575 34.779C49.0157 34.779 49.4389 34.6062 49.7537 34.2977L56.1015 27.9498C56.9481 29.8208 57.2045 31.9054 56.8364 33.9258C56.4682 35.9462 55.4931 37.8064 54.041 39.2585C52.5889 40.7107 50.7286 41.6858 48.7083 42.0539C46.6879 42.422 44.6033 42.1656 42.7323 41.319L31.0975 52.9538C30.4276 53.6237 29.5191 54 28.5718 54C27.6245 54 26.716 53.6237 26.0462 52.9538C25.3763 52.284 25 51.3755 25 50.4282C25 49.4809 25.3763 48.5724 26.0462 47.9025L37.681 36.2677C36.8344 34.3967 36.578 32.3121 36.9461 30.2917C37.3142 28.2713 38.2893 26.4111 39.7415 24.959C41.1936 23.5069 43.0538 22.5318 45.0742 22.1636C47.0946 21.7955 49.1792 22.0519 51.0502 22.8985L44.7192 29.2295L44.7023 29.2463Z"
      stroke="#27CEAA"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M23 21L23.8843 23.3896C24.6946 25.5791 26.4209 27.3054 28.6104 28.1157L31 29L28.6104 29.8843C26.4209 30.6946 24.6946 32.4209 23.8843 34.6104L23 37L22.1157 34.6104C21.3054 32.4209 19.5791 30.6946 17.3896 29.8843L15 29L17.3896 28.1157C19.5791 27.3054 21.3054 25.5791 22.1157 23.3896L23 21Z"
      stroke="#27CEAA"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'SettingDevSvg'
}
</script>
