<template>
  <x-page
    :breadcrumbs="breadcrumbs"
  >
    <div class="dashboard pt-5">
      <div class="dashboard__heading headline-2 mb-5">
        <x-icon
          class="back-button"
          name="chevron-left"
          size="s"
          @click="$router.push('/new_dashboards')"
        />
        <x-icon
          :name="getMarketplaceName(marketplace_id)"
          size="s"
        />
        {{ shop_name }}
      </div>
      <div
        v-if="loadingData"
        class="dashboard__filters mb-5"
        :class="{'not-permit-by-tariff': !isFiltersPermitByTariff}"
      >
        <new-dashboards-filters
          :period="period"
          @change:period="onPeriodChange"
          @change:show-by="onShowBy"
        />
      </div>
      <div
        v-if="loadingData"
        class="dashboard__row content-columns content-columns_content_a1-b1"
      >
        <div class="column column_layout_a">
          <the-summary
            :indicators="summary"
            :loading="isLoading.includes('tile')"
          />
        </div>
        <div class="column column_layout_b">
          <simple-scatter
            heading="is_shop_active"
            :points="is_shop_active"
            :loading="isLoading.includes('is_shop_active')"
          />
        </div>
      </div>
      <template>
        <div
          v-if="!loadingData"
          class="dashboard__row"
        >
          <development />
        </div>
        <div
          v-else
          class="dashboard__row"
        >
          <div class="d-flex justify-between align-center heading__row">
            <h4 class="heading__row__head">
              Сводка
            </h4>
            <div class="d-flex justify-between align-center heading__row__button">
              <button
                class="toggle-btn"
                @click="settingMetrics"
              >
                <x-icon
                  class="mr-1"
                  name="settings"
                  size="xs"
                />
                Настроить метрики
              </button>
              <button
                class="toggle-btn"
                @click="toggleShowGrafic"
              >
                {{ textButton }}
                <x-icon
                  name="chevron-right"
                  size="xs"
                />
              </button>
            </div>
          </div>
          <multiple-areas
            :dates="dates"
            :points="metrics"
            :show-day="show_day"
            :show-week="show_week"
            :checked-metrics-ids="checkedMetrics"
            :free-color-indexs="freeColorIndexs"
            :show-grafic="showGrafic"
            :loadind="isLoading.includes('statistic')"
            @metric-checked="checkMetric"
          />
          <grid
            v-if="!isLoading.length"
            auto
            actions-heading=""
            id-field-name="name"
            selectable
            added-color
            select-by="name"
            :columns="columns"
            :cells="metrics"
            :checked-cells-ids="checkedMetrics"
            :disabled="checkedMetrics.length === 7"
            tooltip-text="Можно выбрать не более 7"
            is-show-check-tooltip
            @cell-checked="checkMetric"
          >
            <template #append="{ cell }">
              <span
                v-if="cell.group_name"
                class="metrics-grid__group-name"
              >
                {{ getGroupName(cell) }}
              </span>
            </template>
            <template #before="{ row }">
              <div
                class="metrics-grid__block__color"
                :class="getColor(row)"
              />
            </template>
            <template #name="{ row }">
              {{ GraficsName[row.name.toUpperCase()] }}
            </template>

            <template #value="{ value }">
              {{ value.value }}

              <div
                v-if="value.dd_value !== null && value.dd_percent !== null && value.nn_value !== null && value.nn_percent !== null"
                class="metrics-grid__meta"
              >
                <div
                  v-if="show_day"
                  class="metrics-grid__meta__value"
                  :class="getBackgroundStyle(value.name, value.dd_value)"
                >
                  Д/Д {{ value.dd_value }} ({{ value.dd_percent }}%)
                </div>
                <div
                  v-if="show_week"
                  class="metrics-grid__meta__value"
                  :class="getBackgroundStyle(value.name, value.nn_value)"
                >
                  Н/Н {{ value.nn_value }} ({{ value.nn_percent }}%)
                </div>
              </div>
            </template>
          </grid>
        </div>
      </template>
    </div>
    <setting-metrics
      @save-checked-metrics="saveCheckedMetrics"
    />
  </x-page>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import moment from 'moment'
import formatDate from '@/utils/date-formatter'
import formatterDataDashboards from '@/utils/formatterDataDashboards'
import formatPrice from '@/utils/price-formatter'
import toastNotification from '@/utils/toast-notification'
import GraficsName from '@/constants/dashboard_mertics'
import NewDashboardsFilters from '@/components/Dashboard/NewDashboardShopFilters.vue'
import TheSummary from '@/components/Dashboard/NewSummary.vue'
import MultipleAreas from '@/components/Dashboard/Graphs/SuperMultipleAreas.vue'
import SimpleScatter from '@/components/Dashboard/Graphs/SimpleScatter.vue'
import Grid from '@/components/Interface/Grid.vue'
import SettingMetrics from '@/components/Modals/SettingMetrics.vue'
import Development from '@/components/Dashboard/Development.vue'
import Measures from '@/constants/measure-name'
import '@/styles/dashboards.styl'
import { getMarketplaceIcon } from '@/utils/getMarketplaceIcon'

const { mapActions } = createNamespacedHelpers('dashboards')
const { mapState } = createNamespacedHelpers('profile')

export default {
  name: 'HomeWithDashboards',

  components: {
    NewDashboardsFilters,
    TheSummary,
    MultipleAreas,
    SimpleScatter,
    SettingMetrics,
    Grid,
    Development
  },

  data () {
    return {
      breadcrumbs: [{ text: 'Сводка', to: { name: 'new_dashboards' } }],
      Measures,
      GraficsName,
      // TODO: isLoading: false and remove isFiltersPermitByTariff, isSummaryPermitByTariff
      showGrafic: true,
      columns: [],
      metrics: [],
      metricList: [],
      dates: [],
      checkedMetrics: [],
      selectedMetrics: [],
      freeColorIndexs: [],
      colors: ['#1EABF1', '#F2453D', '#8DC252', '#F7A738', '#8554E1', '#D2927E', '#E725C8'],
      colorIndex: 0,
      isLoading: [],
      loadingData: true,
      isFiltersPermitByTariff: true,
      isSummaryPermitByTariff: true,
      is_shop_active: {},
      summary: [],
      shop_name: '',
      marketplace_id: null,
      show_day: true,
      show_week: true,
      period: {
        start: moment(new Date()).subtract(8, 'days').toDate(),
        end: moment(new Date()).subtract(1, 'days').toDate()
      }
    }
  },

  computed: {
    ...mapState(['user']),

    id () {
      return this.$route.params.id
    },

    marketplaceName () {
      return this.$route.params.marketplace
    },

    textButton () {
      return this.showGrafic ? 'Скрыть график' : 'Показать график'
    }
  },

  created () {
    this.summary = [{
      indicator_type: 'boxes',
      indicator_value: '',
      dimension: 'counter'
    }, {
      indicator_type: 'money',
      indicator_value: '',
      dimension: 'rubles'
    }]
    // TODO: delete when yandex back be ready
    if (this.marketplaceName === 'yandex-market') {
      this.loadingData = false
    }
  },

  mounted () {
    this.isLoading = ['is_shop_active', 'tile', 'statistic']
    const period = localStorage.getItem('period')
    if (period) {
      this.period = JSON.parse(period)
    }
    this.getAllData()
  },

  methods: {
    ...mapActions([
      'getMetricsList',
      'getShopActivity',
      'getShopOrderFull',
      'getMetricsStatistic'
    ]),

    getGroupName (cell) {
      return GraficsName[cell.group_name]
    },

    getMarketplaceName (id) {
      let result = ''
      switch (id) {
        case 1:
          result = 'ali'
          break
        case 2:
          result = 'ozon'
          break
        case 4:
          result = 'yandex-market'
          break
        case 9:
          result = 'wildberries'
          break
        default:
          result = ''
      }
      getMarketplaceIcon(result)
      return result
    },

    async getAllData () {
      const payload = {
        id: this.id,
        date_from: formatDate(this.period.start, 'YYYY-MM-DD'),
        date_to: formatDate(this.period.end, 'YYYY-MM-DD')
      }
      this.getShopsActivityList(payload)
      this.getShopOrder(payload)
      const data = await this.getMetricList(payload)
      this.getMetricsStatisticList(payload, data)
    },

    async getMetricList (payload) {
      let metricList = []
      try {
        this.isLoading.push('metric')
        const { isSuccess, data, error } = await this.getMetricsList(payload)
        if (isSuccess) {
          metricList = data
        } else {
          toastNotification.error(error.msg)
        }
      } finally {
        this.isLoading = this.isLoading.filter(loader => loader !== 'metric')
      }
      return metricList
    },

    getGroupingMetrics (data, marketplaceId) {
      const metricList = []
      this.metricList = data.filter(metric => metric.name !== 'IS_SHOP_ACTIVE')
      const selectedMetrics = localStorage.getItem(`${this.user.id}_${marketplaceId}_selected_metrics`)
      this.selectedMetrics = selectedMetrics ? JSON.parse(selectedMetrics) : this.metricList.map(metric => metric.name)
      this.metricList.filter(_ => this.selectedMetrics.includes(_.name)).forEach(_ => {
        const groupIndex = metricList.findLastIndex(metric => metric.group_name === _.group_name)
        if (groupIndex === -1) {
          metricList.push(_)
        } else {
          metricList.splice(groupIndex + 1, 0, { ..._, group_name: '' })
        }
      })
      return metricList
    },

    saveCheckedMetrics (metricsList) {
      this.selectedMetrics = metricsList.map(metric => metric.name)
      localStorage.setItem(`${this.user.id}_${this.marketplace_id}_selected_metrics`, JSON.stringify(this.selectedMetrics))
      let checkedMetrics = localStorage.getItem(`${this.user.id}_${this.marketplace_id}_checked_metrics`)
      if (checkedMetrics) {
        checkedMetrics = JSON.parse(checkedMetrics)
        const newCheckedMetrics = {}
        this.checkedMetrics.forEach(item => {
          if (this.selectedMetrics.includes(item.toUpperCase())) {
            newCheckedMetrics[item] = checkedMetrics[item]
          }
        })
        localStorage.setItem(`${this.user.id}_${this.marketplace_id}_checked_metrics`, JSON.stringify(newCheckedMetrics))
      }
      const payload = {
        id: this.id,
        date_from: formatDate(this.period.start, 'YYYY-MM-DD'),
        date_to: formatDate(this.period.end, 'YYYY-MM-DD')
      }
      this.getMetricsStatisticList(payload, this.metricList)
    },

    async getMetricsStatisticList (payload, metricList) {
      const newPayload = { ...payload }
      newPayload.metrics = metricList.map(metric => metric.name)
      try {
        this.isLoading.push('statistic')
        const { isSuccess, data, error } = await this.getMetricsStatistic(newPayload)
        if (isSuccess) {
          const metricsList = this.getGroupingMetrics(metricList, data.shop.marketplace_id)
          this.createMetrics(data, metricsList)
          this.createColumns(data)
        } else {
          toastNotification.error(error.msg)
        }
      } finally {
        this.isLoading = this.isLoading.filter(loader => loader !== 'statistic')
      }
    },

    async getShopsActivityList (payload) {
      try {
        this.isLoading.push('is_shop_active')
        const start = new Date(payload.date_from)
        const end = new Date(payload.date_to)
        const daysLag = Math.ceil(Math.abs(end.getTime() - start.getTime()) / (1000 * 3600 * 24))
        const newPayload = { ...payload }
        if (daysLag > 30) {
          const newStart = moment(end).subtract(30, 'days').toDate()
          newPayload.date_from = formatDate(newStart, 'YYYY-MM-DD')
        }
        this.is_shop_active = {}
        const { isSuccess, data, error } = await this.getShopActivity(newPayload)
        if (isSuccess) {
          formatterDataDashboards.formatData(this, data, 'is_shop_active')
        } else {
          toastNotification.error(error.msg)
        }
      } finally {
        this.isLoading = this.isLoading.filter(loader => loader !== 'is_shop_active')
      }
    },

    async getShopOrder (payload) {
      try {
        this.isLoading.push('tile')
        const { isSuccess, data, error } = await this.getShopOrderFull(payload)
        if (isSuccess) {
          this.shop_name = data[0].shop.name
          this.marketplace_id = data[0].shop.marketplace_id
          this.summary[0].indicator_value = data[0].orders_count
          this.summary[1].indicator_value = data[0].orders_money
        } else {
          toastNotification.error(error.msg)
        }
      } finally {
        this.isLoading = this.isLoading.filter(loader => loader !== 'tile')
      }
    },

    rememberLocalStarageValue (data) {
      let checkedMetrics = localStorage.getItem(`${this.user.id}_${data.shop.marketplace_id}_checked_metrics`)
      if (checkedMetrics) {
        checkedMetrics = JSON.parse(checkedMetrics)
        this.checkedMetrics = Object.keys(checkedMetrics)
        for (let i = 0; i < 7; i++) {
          if (!Object.values(checkedMetrics).includes(i)) {
            this.freeColorIndexs.push(i)
          }
        }
      }
      return checkedMetrics
    },

    createMetrics (data, metricList) {
      const metrics = []
      let colorIndex = null
      const checkedMetrics = this.rememberLocalStarageValue(data)
      metricList.forEach((prop, index) => {
        const metricName = prop.name.toLowerCase()
        const groupName = prop.group_name
        const metricDatas = data[metricName]
        const metric = {}
        const datas = []
        if (checkedMetrics && this.checkedMetrics.includes(metricName)) {
          colorIndex = checkedMetrics[metricName]
        } else if (index < 7 && !checkedMetrics) {
          this.checkedMetrics.push(metricName)
          colorIndex = index
        } else {
          colorIndex = null
        }
        metricDatas.forEach(m => {
          const factorRounding = prop.type_value === 'PERCENT' ? 2 : 0
          const value = m.value ? m.value.toFixed(factorRounding) : '-'
          datas.push(value)
          metric.name = metricName
          metric.group_name = groupName
          metric.color = colorIndex !== null ? this.colors[colorIndex] : ''
          metric.colorIndex = colorIndex
          metric.data = datas
          metric[formatDate(m.date)] = {
            name: metricName,
            value,
            dd_percent: m.value_dtd ? (((m.value - m.value_dtd) / m.value_dtd) * 100).toFixed(2) : '0.00',
            dd_value: formatPrice(m.value - m.value_dtd, { maximumFractionDigits: factorRounding }),
            nn_percent: m.value_wtw ? (((m.value - m.value_wtw) / m.value_wtw) * 100).toFixed(2) : '0.00',
            nn_value: formatPrice(m.value - m.value_wtw, { maximumFractionDigits: factorRounding })
          }
        })
        metrics.push(metric)
      })
      this.metrics = metrics
    },

    createColumns (data) {
      this.dates = []
      const props = Object.keys(data).filter(key => {
        return Array.isArray(data[key]) && data[key].length
      })
      data[props[0]].forEach(prop => this.dates.push(formatDate(prop.date)))
      const widthStyle = { display: 'block', minWidth: '110px' }
      this.columns = this.dates.map(item => ({
        name: item,
        freezed: false,
        styles: { width: '100%', display: 'block', whiteSpace: 'nowrap', textAlign: 'right' },
        key: item,
        type: 'value'
      }))
      this.columns = [{
        name: 'Метрики',
        freezed: true,
        key: 'name',
        styles: widthStyle,
        type: 'name'
      }, ...this.columns]
    },

    onShowBy (param, value) {
      this[param] = value
    },

    toggleShowGrafic () {
      this.showGrafic = !this.showGrafic
    },

    settingMetrics () {
      this.$modal.show('setting-metrics-modal', { metrics: this.metricList, selectedMetrics: this.selectedMetrics })
    },

    getBackgroundStyle (name, value) {
      let isNegative = false
      if (name === 'drr' ||
        name === 'expired_feedbacks_count' ||
        name === 'products_with_errors' ||
        name === 'expired_fbs_orders' ||
        name === 'wb_avg_delivery_time' ||
        name === 'wb_commission' ||
        name === 'products_with_bad_description' ||
        name === 'products_in_quarantine' ||
        name === 'negative_index' ||
        name === 'questions_unanswered' ||
        name === 'products_0_stock_fbo' ||
        name === 'products_0_stock_fbs' ||
        name === 'products_1_stock_fbo' ||
        name === 'products_1_stock_fbs' ||
        name === 'products_2_5_stock_fbo' ||
        name === 'products_2_5_stock_fbs' ||
        name === 'disputed_orders' ||
        name === 'fbs_delivering_today' ||
        name === 'cancelled_orders_by_seller' ||
        name === 'cancelled_orders_rating' ||
        name === 'shipment_delay_rating' ||
        name === 'products_cancelled' ||
        name === 'products_returned' ||
        name === 'cancelled_orders_by_seller_rating' ||
        name === 'products_with_zero_stock_fbo' ||
        name === 'products_with_zero_stock_fbs') {
        isNegative = true
      }
      const normalMetrics = name === 'paid_views_count' ||
        name === 'ozon_expense_template_adv_money' ||
        name === 'ozon_expense_search_adv_money' ||
        name === 'ozon_expense_brand_adv_money' ||
        name === 'advert_orders_share' ||
        name === 'wb_expense_search_adv_money' ||
        name === 'wb_expense_product_adv_money' ||
        name === 'wb_expense_catalog_adv_money' ||
        name === 'expense_adv_money'
      const normal = value === '0.00' || value === '0' || normalMetrics
      const positive = isNegative ? value < 0 : value > 0
      return normal ? '' : positive ? 'positive' : 'negative'
    },

    onPeriodChange (period) {
      this.period = period
      localStorage.setItem('period', JSON.stringify(period))
      this.getAllData()
    },

    getColor (row) {
      let result = ''
      switch (row.colorIndex) {
        case 0:
          result = 'color_blue'
          break
        case 1:
          result = 'color_red'
          break
        case 2:
          result = 'color_green'
          break
        case 3:
          result = 'color_orange'
          break
        case 4:
          result = 'color_purple'
          break
        case 5:
          result = 'color_brown'
          break
        case 6:
          result = 'color_pink'
          break
        default:
          result = ''
      }
      return result
    },

    checkMetric (cell) {
      const checkedId = this.checkedMetrics.findIndex(name => cell.name === name)
      const metricId = this.metrics.findIndex(metric => cell.name === metric.name)
      let newMetric = this.metrics[metricId]

      if (~checkedId) {
        this.checkedMetrics.splice(checkedId, 1)
        this.freeColorIndexs.unshift(cell.colorIndex)
        newMetric = {
          ...this.metrics[metricId],
          color: '',
          colorIndex: null
        }
      } else if (this.checkedMetrics.length < 7) {
        newMetric = {
          ...this.metrics[metricId],
          color: this.colors[this.freeColorIndexs[0]],
          colorIndex: this.freeColorIndexs[0]
        }
        this.checkedMetrics.push(cell.name)
        this.freeColorIndexs.splice(0, 1)
      }
      this.metrics.splice(metricId, 1, newMetric)
      const storageCheckedMetrics = this.checkedMetrics.reduce((checked, metric) => {
        const fullMetric = this.metrics.find(_ => _.name === metric)
        checked[metric] = fullMetric.colorIndex
        return checked
      }, {})
      localStorage.setItem(`${this.user.id}_${this.marketplace_id}_checked_metrics`, JSON.stringify(storageCheckedMetrics))
    }
  }
}
</script>

<style lang="stylus" scoped>
  .back-button
    cursor pointer
  .heading
    &__row
      background-color #ffffff
      padding 30px 20px
      &__button
        gap 5px
      &__head
        font-size 20px
        font-family 'proxima_nova'
        color #3C3C47
  .toggle-btn
    border 1px solid #CACAD3
    border-radius: 4px;
    color #3C3C47
    font-family 'proxima_nova'
    padding: 8px 10px 8px 6px;
    display flex
    align-items center
    justify-content space-between
  .metrics-grid
    &__group-name
      display inline-block
      font-size 14px
      margin 4px 8px
    &__block
      min-width 6px
      height 100%
      &__color
        height 100%
        min-width 6px
        margin-right 6px
        &.color_red
          background-color #F2453D
        &.color_blue
          background-color #1EABF1
        &.color_orange
          background-color #F7A738
        &.color_green
          background-color #8DC252
        &.color_purple
          background-color #8554E1
        &.color_brown
          background-color #D2927E
        &.color_pink
          background-color #E725C8
    &__meta
      width 100%
      &__value
        width 100%
        margin-top 5px
        text-align left
        background-color #F7F7F8
      .positive
        background-color #EEFFF6
      .negative
        background-color #FFEBEE
</style>
